<template>
  <div id="Channel">
    <b-overlay
      variant="transparent"
      :show="overlay"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mb-5">
        <b-col
          md="8"
          class="pb-3 pb-md-0"
        >
          <div>
            <h1>{{ $t("myChannel") }}</h1>
            <h5 class="text-primary">
              {{ $t("myChannelList") }}
            </h5>
          </div>
        </b-col>
        <b-col
          class="align-items-center"
          style="text-align: end;"
        >
          <b-button
            v-if="isChild"
            variant="danger"
            @click="goTo(null, true)"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10">
          <b-breadcrumb>
            <b-breadcrumb-item
              v-for="(brc, index) in navItems"
              :key="brc.text + '_' + brc.id"
              :text="brc.text"
              :active="index === navItems.length - 1 ? true : false"
              @click.stop.prevent="index !== navItems.length - 1 ? goTo(brc.id) : ''"
            />
          </b-breadcrumb>
        </b-col>
        <b-col style="text-align: -webkit-right;">
          <b-button
            v-if="channels.length<4"
            variant="primary"
            class="d-flex"
            @click="gotToView(isChild, 1)"
          >
            <feather-icon
              icon="FolderPlusIcon"
              class="mr-50"
            />
            <span class="text-nowrap">
              {{ $t('createChannel') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="channel in channels"
          :key="channel.node.id"
          class="mt-2"
          md="6"
          lg="3"
          xl="3"
          sm="12"
        >
          <b-card
            class="cursor-pointer cardContent"
            no-body
            @click="isChild ? gotToView(channel.node.id) : goTo(channel.node.id)"
          >
            <b-img-lazy
              :src="buildImageUrl(channel.node.image)"
              class="card-img-top "
              img-alt="Card image"
              fluid-grow
              @error="errorImg"
            />
            <b-card-header>
              <div id="acortarPadre">
                <b-card-text class="mb-0">
                  {{ channel.node.name }}
                </b-card-text>
              </div>
            </b-card-header>
            <b-card-body>
              <div class="actions">
                <div class="action">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        color="white"
                        icon="MoreVerticalIcon"
                        size="20"
                      />
                    </template>
                    <b-dropdown-item @click.stop.prevent="gotToView(channel.node.id)">
                      <feather-icon
                        icon="Edit2Icon"
                        size="18"
                      />
                      {{ $t("editChannel") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop.prevent="deleteChannel(channel.node.id)">
                      <feather-icon
                        icon="DeleteIcon"
                        size="18"
                      />
                      {{ $t("eliminarCanal") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>

import {
  BRow,
  BImgLazy,
  BCol,
  BButton,
  BCardText,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCard,
  BBreadcrumbItem,
  BBreadcrumb,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError } from '@/store/functions'

import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardBody,
    BCard,
    BImgLazy,
    BDropdownItem,
    BCardText,
    BDropdown,
    BRow,
    BCol,
    BButton,
    BBreadcrumbItem,
    BBreadcrumb,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      overlay: false,
      channels: [],
      isChild: this.$route.params.id != null ? this.$route.params.id : null,
      userData: getUserData(),
      headers: [],
      hasChild: false,
      brItems: [{
        id: null,
        text: this.$t('contents.start'),
      },
      ],
      parent: null,
      navItems: [{
        id: null,
        text: this.$t('contents.start'),
      },
      ],
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    if (this.isChild != null) { this.getChannel(this.isChild) } else this.fetchData()
  },
  methods: {
    buildImageUrl(image) {
      return image == null || image.length === 0
        ? noCover
        : image
    },
    errorImg(e) {
      e.target.src = fileError
    },
    getChannel(id) {
      const { headers } = this
      const query = `{
          extendedTags(id:"${id}",client:"${this.userData.profile.client.id}") {
            edges {
              node {
                id
                name
                description
                image
                icon
                children {
                  edges {
                    node {
                      id
                      name
                      image
                      icon
                      children{
                        totalCount
                      }                      
                    }
                  }
                }
              }
            }
          }
        }`
      axios
        .post('', { query }, { headers })
        .then(result => {
          messageError(result, this)
          const res = result.data.data.extendedTags.edges[0]

          this.channels = res.node.children.edges

          this.parent = res.node
          this.brItems.push({
            text: this.parent.name,
            id: this.parent.id,
          })
          if (this.parent != null) { this.recursive(this.parent.id) } else { this.navItems = this.brItems.slice() }
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
        })
    },
    reloadData(id) {
      const { headers } = this
      const query = `{
          extendedTags(id:"${id}",client:"${this.userData.profile.client.id}") {
            edges {
              node {
                id
                name
                description
                image
                icon
                children {
                  edges {
                    node {
                      id
                      name
                      image
                      icon
                      children{
                        totalCount
                      }                      
                    }
                  }
                }
              }
            }
          }
        }`
      axios
        .post('', { query }, { headers })
        .then(result => {
          messageError(result, this)
          const res = result.data.data.extendedTags.edges[0]

          this.channels = res.node.children.edges

          this.parent = res.node
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
        })
    },
    recursive(id) {
      axios
        .post('', {
          query: `{
          extendedTags(id:"${id}",client:"${this.userData.profile.client.id}") {
            edges {
              node {
                id
                name
                description
                image
                icon
                parent{
                    id
                    name
                }               
              }
            }
          }
        }`,
        })
        .then(result => {
          messageError(result, this)
          const res = result.data.data.extendedTags.edges[0].node
          const { parent } = res
          if (parent !== null) {
            const i = parent.id
            const n = parent.name

            this.brItems.splice(1, 0, {
              text: n,
              id: i,
            })
            this.recursive(i)
          } else {
            this.navItems = this.brItems.slice()
          }
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
        })
    },
    goTo(id = null, back = false) {
      if (back) {
        window.history.back()
      }
      if (id != null) {
        this.$router.push({ name: 'myChannel-list', params: { id } })
      } else {
        this.$router.push({ name: 'myChannel' })
      }
    },
    deleteChannel(id) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sureDelChan'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(conf => {
        if (conf.isConfirmed) {
          const query = `mutation{
        deleteExtendedTags(id:"${id}"){
          found
          deletedId
        }
      }`
          axios
            .post('', { query })
            .then(result => {
              messageError(result, this)
              if (this.isChild != null) { this.reloadData(this.isChild) } else this.fetchData()
            })
            .catch(err => {
              console.log(err)
            })
        }
      }).catch(() => {

      })
    },
    gotToView(id, type = 0) {
      if (type === 0) { this.$router.push({ name: 'myChannel-edit', params: { id } }) } else { this.$router.push({ name: 'myChannel-create', params: { parent: id } }) }
    },
    fetchData() {
      const { headers } = this
      const query = `{
          extendedTags(isProfilePreference:true,parent_Isnull:true,client:"${this.userData.profile.client.id}") {
            edges {
              node {
                id
                name
                description
                children{
                    totalCount
                }
                image
                icon              
              }
            }
          }
        }`
      axios
        .post('', { query }, { headers })
        .then(result => {
          messageError(result, this)
          this.channels = result.data.data.extendedTags.edges
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#Channel .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#Channel .card-body {
  padding: 0rem;
}

#Channel .card-body h4 {
  font-size: 1rem !important;
}

#Channel .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#Channel .breadcrumb {
  justify-content: flex-start;
  font-weight: bold;
}

#Channel #button-content {
  padding: 0px;
}

#Channel #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#Channel #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#Channel .cardContent img {
  height: 11vw;
  object-fit: cover;
}
</style>
